import ApiBase from './ApiBase'
import apiUrl from '../config/api'
import {RequestResult} from '@/utils/interface'

//params
interface RegisterParams {
  code: number,
  username: string
}

interface LoginParams extends RegisterParams {
  type: number,
  password: string
}

// response
export interface UserInfoResponse {
  id: number
  hotel_id:number
  username: string
  amount: string
  hotel_name: string
  nickname: string
  headimgurl: string
}

class ApiUser extends ApiBase {
  protected url: string = apiUrl.hClean;
  protected prefix: string = ''
  protected resultDataKey: string = 'data';

  public login(data: LoginParams):Promise<RequestResult<boolean>> {
    return this.post('/delivery/login', data)
  }

  public logout():Promise<RequestResult<boolean>> {
    return this.post('/delivery/loginOut', {})
  }

  public register(data: RegisterParams):Promise<RequestResult<{url:string}>> {
    return this.post('/delivery/reg', data)
  }

  public userInfo():Promise<RequestResult<UserInfoResponse>> {
    return this.post('/delivery/user/info', {})
  }

}

export const apiUser = new ApiUser()
